import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_i = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - I'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>I</h2>
     <LatQuotesIntro />
    
     <p><b>Id est (i.e)</b> - That is.</p>
<p><b>Id quod commune est, nostrum esse dicitur</b> - That which is common is said to be ours.</p>
<p><b>Idem</b> - The same person or thing.</p>
<p><b>Idem nihil dicere et insufficienter dicere est</b> - It is the same to say nothing as not to say enough.</p>
<p><b>Ignorantia facti excusat, ignorantia juris non excusat</b> - Ignorance of fact excuses, ignorance of law does not excuse.</p>
<p><b>Imperium in imperio</b> - A sovereignty within a sovereignty.</p>
<p><b>Impotentia excusat legem</b> - Impossibility is an excuse in the law.</p>
<p><b>Impunitas semper ad deteriora invitat</b> - Impunity always leads to greater crimes.</p>
<p><b>In aequali jure melior est conditio possidentis</b> - When the parties have equal rights, the condition of the possessor is better.</p>
<p><b>In alta proditione nullus potest esse acessorius; sed principalis solum modo</b> - In high treason no one can be an accessory; but a principal only.</p>
<p><b>In Anglia non est interregnum</b> - In England there is no interregnum.</p>
<p><b>In camera</b> - In private.</p>
<p><b>In casu extremae necessitatis omnia sunt communia</b> - In a case of extreme necessity everything is common.		</p>
<p><b>In criminalibus probationes debent esse luce clariores</b> - In criminal cases the proofs ought to be cleared than the light.</p>
<p><b>In curia domini regis, ipse in propria persona jura discernit</b> - In the King s Court, the King himself in his own person dispenses justice.</p>
<p><b>In delicto</b> - At fault.</p>
<p><b>In esse</b> - In existence.</p>
<p><b>In extenso</b> - At full length.</p>
<p><b>In fictione legis aequitas existit</b> - A legal fiction is consistent with equity.</p>
<p><b>In foro conscientiae</b> - In the forum of conscience.</p>
<p><b>In futoro</b> - In the future.</p>
<p><b>In jure non remota causa sed proxima spectatur</b> - In law not the remote but the proximate cause is looked at.</p>
<p><b>In limine</b> - At the outset, on the threshold.</p>
<p><b>In loco parentis</b> - In place of the parent.</p>
<p><b>In mortua manu</b> - In a dead hand.</p>
<p><b>In novo casu novum remedium apponendum est</b> - In a new case a new remedy is to be applied.</p>
<p><b>In omni re nascitur res quae ipsam rem exterminat</b> - In everything is born that which destroys the thing itself.</p>
<p><b>In omnibus</b> - In every respect.</p>
<p><b>In pari delicto potior est conditio possidentis</b> - When the parties are equally in the wrong the condition of the possessor is better.</p>
<p><b>In personam</b> - Against the person.</p>
<p><b>In pleno</b> - In full.</p>
<p><b>In quo quis delinquit in eo de jure est puniendus</b> - In whatever thing one offends in that he is to be punished according to law.</p>
<p><b>In re dubia magis inficiatio quam affirmatio intelligenda</b> - In a doubtful matter the negative is to be understood rather than the affirmative.</p>
<p><b>In republica maxime conservanda sunt jura belli</b> - In a State the laws of war are to be especially observed.</p>
<p><b>In situ</b> - In its place.</p>
<p><b>In terrorem</b> - As a warning or deterrent.</p>
<p><b>In testamentis plenius testatoris intentionem scrutamur</b> - In wills we seek diligently the intention of the testator.</p>
<p><b>In traditionibus scriptorum non quod dictum est, sed quod gestum est, inspicitur</b> - In the delivery of writings (deeds), not what is said but what is done is to be considered.</p>
<p><b>In verbis, non verba sed res et ratio quaerenda est</b> - In words, not words, but the thing and the meaning are to be inquired into.</p>
<p><b>Indicia</b> - Marks, signs.</p>
<p><b>Injuria non excusat injuriam</b> - A wrong does not excuse a wrong.</p>
<p><b>Intentio inservire debet legibus, non leges intentioni</b> - Intention ought to be subservient to the laws, not the laws to the intention.</p>
<p><b>Inter alia</b> - Amongst other things.</p>
<p><b>Interest reipublicae res judicatas non rescindi</b> - It is in the interest of the State that things adjudged be not rescinded.</p>
<p><b>Interest reipublicae suprema hominum testamenta rata haberi</b> - It is in the interest of the State that men s last wills be sustained.</p>
<p><b>Interest reipublicae ut quilibet re sua bene utatur</b> - It is in the interest of the State that every one use properly his own property.</p>
<p><b>Interest reipublicase ut sit finis litium</b> - It is in the interest of the State that there be an end to litigation.</p>
<p><b>Interim</b> - Temporary, in the meanwhile.</p>
<p><b>Interpretare et concordare leges legibus est optimus interpretandi modus</b> - To interpret and harmonize laws is the best method of interpretation.</p>
<p><b>Interpretatio fienda est ut res magis valeat quam pereat</b> - Such a construction is to be made that the thing may have effect rather than it should fail.</p>
<p><b>Interruptio multiplex non tollit praescriptionem semel obtentam</b> - Repeated interruption does not defeat a prescription once obtained.</p>
<p><b>Invito beneficium non datur</b> - A benefit is not conferred upon one against his consent.</p>
<p><b>Ipsissima verba</b> - The very words of a speaker.</p>
<p><b>Ipso facto</b> - By that very fact.</p>
<p><b>Ira furor brevis est</b> - Anger is brief insanity.</p>
<p><b>Iter arma leges silent</b> - In war the laws are silent.</p>




   </Layout>
  )
}

export default LatQuotes_i
